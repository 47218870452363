import { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PacmanLoader, SyncLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/material'

const mdmUrl = process.env.REACT_APP_BASE_URL

const ForgotPassword = (): JSX.Element => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const dispatch = useDispatch()
  const { isLoggedIn, X_Authorization } = useSelector(
    (store: any) => store.users,
  )

  useEffect(() => {
    if (isLoggedIn) navigate('/dashboard')
  }, [isLoggedIn])

  // Separate function for form validation
  const validateForm = () => {
    return (
      email.trim() !== ''
    )
  }

  // Function to handle user login
  const handleReset = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setSubmitted(true)

    if (!validateForm()) {
      toast.error('Please provide your email account', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'login-toast',
        theme: 'colored',
      })
      return
    }

    setLoading(true)
    try {
      // Perform the login if captcha is valid
      const response = await fetch(`${mdmUrl}/api/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      const data = await response.json()

      console.log("data", data)

      if (data.status === 0) {
        toast.success(`${data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'user-toast',
          theme: 'colored',
        })
      } else {
        toast.error(`${data.error}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'login-toast',
          theme: 'colored',
        })
      }
    } catch (err) {
      console.error('Login error:', err)
      toast.error('Reset password failed. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'login-toast',
        theme: 'colored',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {!isLoggedIn && (
        <div className='flex min-h-full flex-col justify-center px-6 py-12 lg:px-8'>
          <ToastContainer />
          <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
            <Stack width={'100%'} alignItems={'center'} my={2}>
              <NavLink
                to=''
                className='bg-gradient-to-r p-5 rounded-full w-20 h-20 flex justify-center items-center from-black from-50% via-sky-500 via-20% to-emerald-500 to-100%'
              >
                <h1 className='text-white font-bold'>MDM</h1>
              </NavLink>
            </Stack>
            <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
              Forgot Password
            </h2>
          </div>
          <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm shadow-2xl p-10'>
            <form className='space-y-6' onSubmit={handleReset}>
              {/* email Input */}
              <div>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Email
                </label>
                <div className='mt-2'>
                  <input
                    id='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type='text'
                    autoComplete='email'
                    className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm placeholder:text-gray-400 focus:ring-red-500 focus:ring-2 sm:text-sm sm:leading-6
                    ${submitted && email.trim() === '' ? 'border-red-500' : 'border-0'}`}
                  />
                </div>
              </div>
          

              {/* Submit Button */}
              <div>
                <button
                  type='submit'
                  className='flex w-full justify-center rounded-md bg-black px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                >
                  {loading ? <PacmanLoader color='#fff' /> : 'Reset'}
                </button>
              </div>
            </form>

            {/* <p className='mt-10 text-center text-sm font-medium text-gray-500'>
              Not Registered?
              <NavLink
                to='/createAccount'
                className='ml-2 font-semibold leading-6 text-indigo-600 hover:text-indigo-500'
              >
                Create Account
              </NavLink>
            </p> */}
          </div>
        </div>
      )}
    </>
  )
}

export default ForgotPassword
