import { lazy } from "react";
import AddTaskModal from "../components/AddTaskModal";
// import TestPage from "../pages/tests/TestPage";
const TestPage = lazy(() => import("../pages/tests/TestPage"))
const TestPage2 = lazy(() => import("../pages/tests/FloatingButton"))
const TestPage3 = lazy(() => import("../pages/tests/TestPage3"))
const User = lazy(() => import("../pages/users/Users"))
const UserType = lazy(() => import("../pages/users/UserType"))
const UserRoleResources = lazy(() => import("../pages/users/UserRoleResources"))
const Model = lazy(() => import("../pages/device/Model"));
const Group = lazy(() => import("../pages/device/Group"));
const GroupsDevices = lazy(() => import("../pages/device/GroupsDevices"));
const Institution = lazy(() => import("../pages/device/Institution"));
const Reports = lazy(() => import("../pages/reports/Reports"))
const Devices = lazy(() => import("../pages/device/Devices"));
const P20Table = lazy(() => import("../components/P20Table"));
const Device = lazy(() => import('../pages/device/components/Device'));
const Configs = lazy(() => import("../pages/configs/Configs"));
const AppCatalogue = lazy(() => import("../pages/app-store/app-catalogue/AppCatalogue"));
const NewApps = lazy(() => import("../pages/app-store/app-catalogue/NewApps"));
const PublishedApps = lazy(() => import("../pages/app-store/app-catalogue/PublishedApps"));
const UnpublishedApps = lazy(() => import("../pages/app-store/app-catalogue/UnpublishedApps"));
const AppTask = lazy(() => import("../pages/app-store/app-distribution/AppTask"));

const router = [
  {
    path: "/dashboard/configs",
    title: 'Users',
    component: Configs
  },
   {
    path: "/dashboard/users/user-role",
    title: 'Users',
    component: UserType
  },
  {
    path: "/dashboard/users/user-role-resources",
    title: 'tests3',
    component: UserRoleResources,
  },
  {
    path: "/dashboard/users",
    title: 'Users',
    component: User
  },
  {
    path: "/dashboard/device/model",
    title: 'Model',
    component: Model
  },
  {
    path: "/dashboard/devices",
    title: 'Devices',
    component: Devices
  },
  {
    path: "/dashboard/devices/:desc",
    title: 'Device',
    component: Device
  },
  {
    path: "/dashboard/device/group",
    title: 'Group',
    component: Group
  },
  {
    path: "/dashboard/device/groupsDevices",
    title: 'GroupsDevices',
    component: GroupsDevices
  },
  {
    path: "/dashboard/device/institution",
    title: 'Institution',
    component: Institution
  },
  {
    path: "/dashboard/app-store/app-catalogue",
    title: 'AppCatalogue',
    component: AppCatalogue
  },
  {
    path: "/dashboard/app-store/app-catalogue/new",
    title: 'NewApps',
    component: NewApps
  },
  {
    path: "/dashboard/app-store/app-catalogue/published",
    title: 'PublishedApps',
    component: PublishedApps
  },
  {
    path: "/dashboard/app-store/app-catalogue/unpublished",
    title: 'UnpublishedApps',
    component: UnpublishedApps
  },
  {
    path: "/dashboard/app-store/app-distribution",
    title: 'AppTask',
    component: AppTask
  }, 
  {
    path: "/dashboard/app-store/add-task",
    title: 'AddTask',
    component: AddTaskModal
  },
  {
    path: "/dashboard/app-store/app-distribution/release",
    title: 'Institution',
    component: Institution
  },
  {
    path: "/dashboard/app-store/app-distribution/installed",
    title: 'Institution',
    component: Institution
  },
  {
    path: "/dashboard/app-store/app-distribution/uninstalled",
    title: 'Institution',
    component: Institution
  },
  {
    path: "/dashboard/reports",
    title: 'Reports',
    component: Reports,
  }, 
  {
    path: "/dashboard/testPage",
    title: 'tests',
    component: TestPage,
  },
  {
    path: "/dashboard/testPage2",
    title: 'tests2',
    component: TestPage2,
  },
  {
    path: "/dashboard/testPage3",
    title: 'tests3',
    component: TestPage3,
  },
];

export default router;