import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PacmanLoader, SyncLoader } from 'react-spinners'
import { Stack, Typography } from '@mui/material'

const mdmUrl = process.env.REACT_APP_BASE_URL

interface FormDataTypes {
  name: string
  username: string
  email: string
  userType: string
  description: string
}

const CreateAccount = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target
    setFormData((prevData: any) => ({ ...prevData, [name]: value }))
  }

  const [formData, setFormData] = useState<FormDataTypes>({
    name: '',
    username: '',
    email: '',
    userType: '030bbb79a84f4a856751238484a0098ba4b295a3f9e31c3e',
    description: 'account created by user',
  })

  // Function to handle user login
  const handleCreateAccount = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await fetch(mdmUrl + '/api/create-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      console.log(data)
      if (data.status === 0) {
        setLoading(false)
        toast.success(`${data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'user-toast',
          theme: 'colored',
        })
        toast.success(`Verify email to proceed`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'user-toast',
          theme: 'colored',
        })
        setFormData({
          name: '',
          username: '',
          email: '',
          userType: '030bbb79a84f4a856751238484a0098ba4b295a3f9e31c3e',
          description: 'account created by user',
        })
      } else {
        setLoading(false)
        toast.error(`${data.error}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'login-toast',
          theme: 'colored',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {true && (
        <div className='flex min-h-full flex-col justify-center px-6 py-12 lg:px-8'>
          <ToastContainer />
          <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
            <Stack width={'100%'} alignItems={'center'} my={2}>
              <NavLink
                to=''
                className='bg-gradient-to-r p-5 rounded-full w-20 h-20 flex justify-center items-center from-black from-50% via-sky-500 via-20% to-emerald-500 to-100%'
              >
                <h1 className='text-white font-bold'>MDM</h1>
              </NavLink>
            </Stack>
            <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
              Create account
            </h2>
          </div>
          <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm shadow-2xl p-10'>
            <form>
              <div className='flex flex-col mt-5'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Name
                </label>
                <input
                  type='text'
                  onChange={handleChange}
                  name='name'
                  value={formData.name}
                  className='outline-none w-full rounded-md border-0 py-3 px-2 mt-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:font-normal font-bold focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  placeholder='Name'
                />
              </div>
              <div className='flex flex-col mt-5'>
              <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Username
                </label>
                <input
                  type='text'
                  onChange={handleChange}
                  name='username'
                  value={formData.username}
                  className='outline-none w-full rounded-md border-0 py-3 px-2 mt-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:font-normal font-bold focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  placeholder='Username'
                />
              </div>
              <div className='flex flex-col mt-5'>
              <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Email
                </label>
                <input
                  type='text'
                  onChange={handleChange}
                  name='email'
                  value={formData.email}
                  className='outline-none w-full rounded-md border-0 py-3 px-2 mt-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:font-normal font-bold focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  placeholder='Email'
                />
              </div>
            </form>
            {/* <Stack width={'100%'} mt={3}>
              <button
                onClick={handleCreateAccount}
                className="flex w-full justify-center rounded-md bg-black px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {loading ? <PacmanLoader color='#fff' /> : 'Create'}
              </button>
            </Stack> */}

            <p className='mt-10 text-center text-sm font-medium text-gray-500'>
              Already have an account?
              <NavLink
                to='/'
                className='ml-2 font-semibold leading-6 text-indigo-600 hover:text-indigo-500'
              >
                Login
              </NavLink>
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default CreateAccount
