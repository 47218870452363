import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import SidebarLinkGroup from './SidebarLinkGroup'
import { MdDashboard } from 'react-icons/md'
import { FaUser } from 'react-icons/fa'
import { BsDeviceSsdFill } from 'react-icons/bs'
import { BiSolidReport } from 'react-icons/bi'
import { GrConfigure } from 'react-icons/gr'
import { IoMdAppstore } from 'react-icons/io'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { useDispatch, useSelector } from 'react-redux'
import { setIsOpen, setWidth } from '../features/slices/sidebarSlice'
interface SidebarProps {
  sidebarOpen: boolean
  setSidebarOpen: (arg: boolean) => void
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const location = useLocation()
  const { pathname } = location

  const trigger = useRef<any>(null)
  const sidebar = useRef<any>(null)

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true',
  )

  const navigate = useNavigate()

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) {
        return
      }
      handleSideWidth() //collapse sidebar when escape key is clicked
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString())
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded')
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded')
    }
  }, [sidebarExpanded])

  const sidebarWidth = useSelector((state: any) => state.sidebar.width)

  const dispatch = useDispatch()

  const handleSideWidth = () => {
    if (sidebarWidth === 'w-72.5') {
      dispatch(setWidth('w-0'))
      dispatch(setIsOpen(false))
      setSidebarOpen(false)
    } else if (sidebarWidth === 'w-0') {
      dispatch(setWidth('w-72.5'))
      dispatch(setIsOpen(true))
      setSidebarOpen(true)
    }
  }

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 768) {
  //       dispatch(setWidth('w-0'))
  //       dispatch(setIsOpen(false))
  //       setSidebarOpen(false)
  //     } else {
  //       dispatch(setWidth('w-72.5'))
  //       dispatch(setIsOpen(true))
  //       setSidebarOpen(true)
  //     }
  //   }

  //   window.addEventListener('resize', handleResize)
  //   handleResize() // Call once on mount to set initial state

  //   return () => window.removeEventListener('resize', handleResize)
  // }, [dispatch, setSidebarOpen])

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-9999 flex h-screen ${sidebarWidth} flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className='flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5'>
        <NavLink
          to='/'
          className='bg-gradient-to-r p-5 rounded-full w-20 h-20 flex justify-center items-center from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%'
        >
          <h1 className='text-white font-bold'>MDM</h1>
        </NavLink>
        <MenuOpenIcon
          onClick={() => handleSideWidth()}
          sx={{ color: 'white' }}
          className='cursor-pointer md:hidden'
        />
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className='no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear'>
        {/* <!-- Sidebar Menu --> */}
        <nav className='mt-5 py-4 px-4 lg:mt-9 lg:px-6'>
          {/* <!-- Menu Group --> */}
          <div>
            <ul className='mb-6 flex flex-col gap-1.5'>
              {/* <!-- Menu Item Dashboard --> */}
              <SidebarLinkGroup
                activeCondition={
                  pathname === '/' || pathname.includes('dashboard')
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to='/dashboard'
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                          pathname === '/dashboard' || pathname === '/'
                            ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                            : 'text-white'
                        }`}
                      >
                        <MdDashboard size={20} />
                        Dashboard
                      </NavLink>
                    </React.Fragment>
                  )
                }}
              </SidebarLinkGroup>
              {/* <!-- Menu Item Dashboard --> */}

              {/* <!-- Menu Item users --> */}
              <SidebarLinkGroup
                activeCondition={
                  pathname === '/users' || pathname.includes('users')
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to='#'
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                          pathname === '/dashboard/users' ||
                          pathname.includes('users')
                            ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                            : 'text-white'
                        }`}
                        onClick={(e) => {
                          e.preventDefault()
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true)
                        }}
                      >
                        <FaUser size={20} />
                        Users
                        <svg
                          className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                            open && 'rotate-180'
                          }`}
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z'
                            fill=''
                          />
                        </svg>
                      </NavLink>
                      {/* <!-- Dropdown Menu Start --> */}
                      <div
                        className={`translate transform overflow-hidden ${
                          !open && 'hidden'
                        }`}
                      >
                        <ul className='mt-4 mb-5.5 flex flex-col gap-2.5 pl-6'>
                          <li>
                            <NavLink
                              to='/dashboard/users/user-role'
                              className={({ isActive }) =>
                                `group relative flex items-center text-sm font-semibold gap-2.5 rounded-md px-4 duration-300 ease-in-out hover:text-white 
                                ${
                                  pathname === '/dashboard/users/user-role'
                                    ? // ||
                                      // pathname.includes('user-role')
                                      'bg-graydark dark:bg-meta-4 text-greenButton'
                                    : 'text-white'
                                }`
                              }
                            >
                              User Roles
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/dashboard/users'
                              className={({ isActive }) =>
                                `group relative flex items-center text-sm font-semibold gap-2.5 rounded-md px-4 duration-300 ease-in-out hover:text-white 
                              ${
                                pathname === '/dashboard/users'
                                  ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                                  : 'text-white'
                              }`
                              }
                            >
                              Users
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      {/* <!-- Dropdown Menu End --> */}
                    </React.Fragment>
                  )
                }}
              </SidebarLinkGroup>
              {/* <!-- Menu Item user --> */}

              {/* <!-- Menu Item model --> */}
              <SidebarLinkGroup
                activeCondition={
                  pathname === '/model' || pathname.includes('model')
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to='#'
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                          pathname === '/dashboard/device' ||
                          pathname.includes('device')
                            ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                            : 'text-white'
                        }`}
                        onClick={(e) => {
                          e.preventDefault()
                          // sidebarExpanded && navigate('/dashboard/devices')
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true)
                        }}
                      >
                        <BsDeviceSsdFill size={20} />
                        Entities
                        <svg
                          className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                            open && 'rotate-180'
                          }`}
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z'
                            fill=''
                          />
                        </svg>
                      </NavLink>
                      {/* <!-- Dropdown Menu Start --> */}
                      <div
                        className={`translate transform overflow-hidden ${
                          !open && 'hidden'
                        }`}
                      >
                        <ul className='mt-4 mb-5.5 flex flex-col gap-2.5 pl-6'>
                          <li>
                            <NavLink
                              to='/dashboard/devices'
                              className={({ isActive }) =>
                                `group relative flex items-center text-sm font-semibold gap-2.5 rounded-md px-4 duration-300 ease-in-out hover:text-white 
                              ${
                                pathname === '/dashboard/devices' ||
                                pathname.includes('devices')
                                  ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                                  : 'text-white'
                              }`
                              }
                            >
                              Devices
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/dashboard/device/model'
                              className={({ isActive }) =>
                                `group relative flex items-center text-sm font-semibold gap-2.5 rounded-md px-4 duration-300 ease-in-out hover:text-white 
                              ${
                                pathname === '/dashboard/device/model' ||
                                pathname.includes('model')
                                  ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                                  : 'text-white'
                              }`
                              }
                            >
                              Models
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/dashboard/device/institution'
                              className={({ isActive }) =>
                                `group relative flex items-center text-sm font-semibold gap-2.5 rounded-md px-4 duration-300 ease-in-out hover:text-white ' 
                              ${
                                pathname === '/dashboard/device/institution' ||
                                pathname.includes('institution')
                                  ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                                  : 'text-white'
                              }`
                              }
                            >
                              Institutions
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/dashboard/device/group'
                              className={({ isActive }) =>
                                `group relative flex items-center text-sm font-semibold gap-2.5 rounded-md px-4 duration-300 ease-in-out hover:text-white ' 
                              ${
                                pathname === '/dashboard/device/group' ||
                                pathname.includes('group')
                                  ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                                  : 'text-white'
                              }`
                              }
                            >
                              Groups
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      {/* <!-- Dropdown Menu End --> */}
                    </React.Fragment>
                  )
                }}
              </SidebarLinkGroup>
              {/* <!-- Menu Item model --> */}

              {/* <!-- Menu Item app store --> */}
              <SidebarLinkGroup
                activeCondition={
                  pathname === '/app-store' || pathname.includes('app-store')
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to='#'
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                          pathname === '/dashboard/app-store' ||
                          pathname.includes('app-store')
                            ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                            : 'text-white'
                        }`}
                        onClick={(e) => {
                          e.preventDefault()
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true)
                        }}
                      >
                        <IoMdAppstore size={20} />
                        App store
                        <svg
                          className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                            open && 'rotate-180'
                          }`}
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z'
                            fill=''
                          />
                        </svg>
                      </NavLink>
                      {/* <!-- Dropdown Menu Start --> */}
                      <div
                        className={`translate transform overflow-hidden ${
                          !open && 'hidden'
                        }`}
                      >
                        <ul className='mt-4 mb-5.5 flex flex-col gap-2.5 pl-6'>
                          <SidebarLinkGroup
                            activeCondition={
                              pathname === '/app-catalogue' ||
                              pathname.includes('app-catalogue')
                            }
                          >
                            {(handleClick, open) => {
                              return (
                                <React.Fragment>
                                  <li>
                                    <NavLink
                                      to='/dashboard/app-store/app-catalogue'
                                      className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 text-sm font-semibold duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                        pathname ===
                                          '/dashboard/app-store/app-catalogue' ||
                                        pathname.includes('app-catalogue')
                                          ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                                          : 'text-white'
                                      }`}
                                      onClick={(e) => {
                                        e.preventDefault()
                                        navigate(
                                          '/dashboard/app-store/app-catalogue',
                                        )
                                      }}
                                    >
                                      Apps
                                    </NavLink>
                                    <NavLink
                                      to='/dashboard/app-store/app-distribution'
                                      className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                        pathname ===
                                          '/dashboard/app-store/app-distribution' ||
                                        pathname ===
                                          '/dashboard/app-store/add-task' ||
                                        pathname.includes('app-distribution')
                                          ? 'bg-graydark dark:bg-meta-4 text-greenButton'
                                          : 'text-white'
                                      }`}
                                      onClick={(e) => {
                                        e.preventDefault()
                                        sidebarExpanded &&
                                          navigate(
                                            '/dashboard/app-store/app-distribution',
                                          )
                                        sidebarExpanded
                                          ? handleClick()
                                          : setSidebarExpanded(true)
                                      }}
                                    >
                                      Tasks
                                    </NavLink>
                                  </li>
                                </React.Fragment>
                              )
                            }}
                          </SidebarLinkGroup>
                        </ul>
                      </div>
                      {/* <!-- Dropdown Menu End --> */}
                    </React.Fragment>
                  )
                }}
              </SidebarLinkGroup>
              {/* <!-- Menu Item app store --> */}

              {/* <!-- Menu Item reports --> */}
              <SidebarLinkGroup
                activeCondition={
                  pathname === '/reports' || pathname.includes('reports')
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <li>
                        <NavLink
                          to='/reports'
                          className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                            pathname.includes('settings') &&
                            'bg-graydark dark:bg-meta-4'
                          }`}
                          onClick={(e) => {
                            e.preventDefault()
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true)
                          }}
                        >
                          <BiSolidReport size={20} />
                          Reports
                          <svg
                            className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                              open && 'rotate-180'
                            }`}
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z'
                              fill=''
                            />
                          </svg>
                        </NavLink>
                      </li>
                    </React.Fragment>
                  )
                }}
              </SidebarLinkGroup>

              {/*Configs  */}
              <SidebarLinkGroup
                activeCondition={
                  pathname === '/dashboard/configs' ||
                  pathname.includes('configs')
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <li>
                        <NavLink
                          to='/dashboard/configs'
                          className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                            pathname.includes('configs') &&
                            'bg-graydark dark:bg-meta-4'
                          }`}
                          onClick={(e) => {
                            e.preventDefault()
                            navigate('/dashboard/configs')
                          }}
                        >
                          <GrConfigure size={20} />
                          Configs
                        </NavLink>
                      </li>
                    </React.Fragment>
                  )
                }}
              </SidebarLinkGroup>

              {/* <!-- Menu Item Settings --> */}
            </ul>
          </div>
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  )
}

export default Sidebar
