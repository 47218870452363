import { createSlice } from '@reduxjs/toolkit'

interface UserTypeState {
  name: string
  description: string
  id: string
  resourceId: string
 
}

const initialState: UserTypeState = {
  name: "",
  id: "",
  description: "",
  resourceId: "",
}

const userTypeSlice = createSlice({
  name: 'userType',
  initialState,
  reducers: {
    setUserTypeDetails: (state, action) => {
      state.name = action.payload.name
      state.description = action.payload.description
      state.id = action.payload.id
    }, 
    setResourceId: (state, action) => {
      state.resourceId = action.payload
    },  
  },
})

export const {
  setUserTypeDetails, setResourceId
  
} = userTypeSlice.actions
export default userTypeSlice.reducer
